import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Meta from "../components/Meta"
import Layout from "../components/Layout"
import Container from "../components/common/Container"
import RichText from "../components/RichText"
import BlogFrame from "../components/BlogFrame"
import PageHeader from "../components/PageHeader"
import SliceMachine from "../slices/SliceMachine"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { sm, md } from "../utils/breakpoints"

const CategoryMargin = styled.div`
  margin-bottom: 3rem;
`

const SubscribeButtons = styled.div`
  margin-top: 3rem;
  max-width: 80rem;
  margin: 3rem auto 0 auto;
  justify-content: center;

  img {
    height: 32px;
  }

  a {
    margin: 0 1rem;
    display: block;
    color: white;
    border-radius: 6px;
    font-size: 0.8em;
    display: inline-block;
  }

  a:first-of-type {
    color: #1db954;
    border-color: #1db954;
  }

  a:last-of-type {
    color: #b150e2;
    border-color: #b150e2;
  }
`

const SeasonJump = styled.div`
  margin-top: 2rem;

  a {
    display: block;
    border-radius: 4px;
    background: ${props => props.theme.colors.secondary};
    text-align: center;
    font-family: "Raleway";
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    margin: 1rem 0 2rem 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.1s ease-in;
    padding: 1rem;
  }

  @media ${md} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto 0 auto;
    a {
      margin: 0 0.5rem;
    }
  }
`

const MakeItWork = ({ data, pageContext, location }) => {
  const page = data.allPrismicPodcastPillar.nodes[0].data
  const seasons = page.body
  return (
    <Layout
      location={location}
      prismicLayout={data.prismicLayout}
      prismicSiteBanner={data.prismicSitebanner}>
      <Meta title={"Make it Work Podcast"} canonical={location.href} />
      <Container>
        <PageHeader
          headerText={<RichText render={page?.title?.richText} />}
          subHeader={<RichText render={page?.subtitle?.richText} />}>
          <SubscribeButtons>
            <a href={page.subscribe_to_spotify_link.url}>
              <img
                src="https://images.prismic.io/yarno/4f5334c8-9cd8-4f27-b08e-387bae009948_spotify.png?auto=compress,format"
                alt="Listen and subscribe on Spotify"
              />
            </a>
            <a href={page.subscribe_to_apple_link.url}>
              <img
                src="https://images.prismic.io/yarno/5459359f-b9f4-4f32-9e28-87a8ad6f2c3e_apple-podcast.png?auto=compress,format"
                alt="Listen and subscribe on Apple Podcasts"
              />
            </a>
          </SubscribeButtons>

          <SeasonJump>
            {seasons.map(season => {
              return (
                <a
                  key={season.id}
                  href={`#season-${season.primary.season_number}`}>
                  Season {season.primary.season_number}
                </a>
              )
            })}
          </SeasonJump>
        </PageHeader>
      </Container>
      <BlogFrame>
        <Container>
          <SliceMachine body={seasons} />
          <CategoryMargin />
        </Container>
      </BlogFrame>
    </Layout>
  )
}

export default MakeItWork

export const postQuery = graphql`
  query {
    ...PrismicSiteBanner
    ...PrismicLayout
    allPrismicPodcastPillar {
      nodes {
        id
        data {
          subscribe_to_spotify_link {
            url
          }
          subscribe_to_apple_link {
            url
          }
          title {
            richText
          }
          subtitle {
            richText
          }
          body {
            ... on PrismicPodcastPillarDataBodyPodcastSeason {
              id
              slice_type
              slice_label
              primary {
                season_description {
                  richText
                }
                season_image {
                  alt
                  gatsbyImageData(width: 250, placeholder: NONE)
                }
                season_number
                season_subtitle {
                  richText
                }
                season_title {
                  richText
                }
              }
              items {
                episode {
                  document {
                    ... on PrismicPodcastEpisode {
                      id
                      uid
                      data {
                        episode_number
                        season_number
                      }
                    }
                  }
                }
                episode_embed_url
                episode_description {
                  richText
                }
                episode_transcript {
                  richText
                }
                episode_number
                episode_title {
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`